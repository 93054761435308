import http from "../axios/http"

//系统配置设置
export let getSystemIndex=(params)=>{
    return http.get("/system/system_index",{params})
}
//系统参数配置保存
export let getSystemSave=(params)=>{
    return http.post("/system/system_update",params)
}

