<template>
  <div class="DisposeSetup page">
    <a-tabs v-model:activeKey="activeKey">
      <a-tab-pane key="1" tab="系统信息">
        <div class="tab1">
          <a-form>
            <a-form-item label="系统名称">
              <a-input
                placeholder="请输入"
                v-model:value="data.system_full_name"
              />
            </a-form-item>
            <a-form-item label="版权所有">
              <a-input placeholder="请输入" v-model:value="data.copy_right" />
            </a-form-item>
            <a-form-item label="浏览器Logo">
              <Upload />
              <!-- <div style="display: flex; flex-direction: column">
                <a-upload
                  v-model:file-list="fileList"
                  name="file"
                  :multiple="true"
                  action="/system/system_index"
                  :headers="headers"
                  @change="handleChange"
                >
                  <a-button type="primary">
                    <upload-outlined></upload-outlined>
                    上传图片
                  </a-button>
                </a-upload>
              </div> -->
            </a-form-item>
            <a-form-item label="登录背景图片">
              <Upload />
            </a-form-item>
            <div class="bottom">
              <a-button type="primary" @click="onSubmit">保存</a-button>
              <!-- <a-button style="margin-left: 10px" @click="resetForm"
                >取消</a-button
              > -->
            </div>
          </a-form>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="微信信息">
        <div class="tab2">
          <a-form>
            <a-form-item label="微信登录AppID">
              <a-input placeholder="请输入" v-model:value="data.wx_app_id" />
            </a-form-item>
            <a-form-item label="微信登录密钥">
              <a-input
                placeholder="请输入"
                v-model:value="data.wx_login_secret"
              />
            </a-form-item>
            <a-form-item label="微信公众号AppID">
              <a-input placeholder="请输入" v-model:value="data.wx_app_id" />
            </a-form-item>
            <a-form-item label="微信公众号密钥">
              <a-input
                placeholder="请输入"
                v-model:value="data.wx_app_secret"
              />
            </a-form-item>
            <a-form-item label="模板ID">
              <a-input
                placeholder="请输入"
                v-model:value="data.wx_template_id"
              />
            </a-form-item>
            <a-form-item label="公众号二维码">
              <Upload />
            </a-form-item>
            <!-- <a-form-item label="微信公众号AppID">
              <a-input placeholder="请输入"  />
            </a-form-item> -->
            <!-- <a-form-item label="微信公众号密钥">
              <a-input placeholder="请输入"  />
            </a-form-item> -->
            <a-form-item label="商户号">
              <a-input
                placeholder="请输入"
                v-model:value="data.wx_program_mchid"
              />
            </a-form-item>
            <a-form-item label="商户支付密钥">
              <a-input
                placeholder="请输入"
                v-model:value="data.wx_program_mchid_key"
              />
            </a-form-item>
            <div class="bottom">
              <a-button type="primary" @click="onSubmit">保存</a-button>
              <!-- <a-button style="margin-left: 10px" @click="resetForm"
                >取消</a-button
              > -->
            </div>
          </a-form>
        </div>
      </a-tab-pane>
      <a-tab-pane key="3" tab="支付宝信息">
        <div class="tab3">
          <a-form>
            <a-form-item label="支付宝应用AppID">
              <a-input
                placeholder="请输入"
                v-model:value="data.alipay_app_id"
              />
            </a-form-item>
            <a-form-item label="支付宝商户私钥">
              <a-textarea
                placeholder="请输入"
                v-model:value="data.alipay_private_key"
              />
            </a-form-item>
            <a-form-item label="支付宝公钥">
              <a-textarea
                placeholder="请输入"
                v-model:value="data.alipay_public_key"
              />
            </a-form-item>
            <div class="bottom">
              <a-button type="primary" @click="onSubmit">保存</a-button>
              <!-- <a-button style="margin-left: 10px" @click="resetForm"
                >取消</a-button
              > -->
            </div>
          </a-form>
        </div>
      </a-tab-pane>
      <a-tab-pane key="4" tab="小程序信息">
        <div class="tab4">
          <a-form>
            <a-form-item label="小程序AppID">
              <a-input
                placeholder="请输入"
                v-model:value="data.wx_program_app_id"
              />
            </a-form-item>
            <a-form-item label="小程序密钥">
              <a-input
                placeholder="请输入"
                v-model:value="data.wx_program_app_secret"
              />
            </a-form-item>
            <a-form-item label="商户号">
              <a-input
                placeholder="请输入"
                v-model:value="data.wx_program_mchid"
              />
            </a-form-item>
            <a-form-item label="商户支付密钥">
              <a-input placeholder="请输入" v-model:value="data.wx_mchid_key" />
            </a-form-item>
            <a-form-item label="小程序包下载">
              <a-button type="primary">下载文件</a-button>
            </a-form-item>
            <a-form-item label="小程序二维码">
              <Upload />
            </a-form-item>
            <div class="bottom">
              <a-button type="primary" @click="onSubmit">保存</a-button>
              <!-- <a-button style="margin-left: 10px" @click="resetForm"
                >取消</a-button
              > -->
            </div>
          </a-form>
        </div>
      </a-tab-pane>
      <a-tab-pane key="5" tab="短信信息">
        <div class="tab4">
          <a-form>
            <a-form-item label="短信 ID">
              <a-input placeholder="请输入" v-model:value="data.sms_userid" />
            </a-form-item>
            <a-form-item label="短信账号">
              <a-input placeholder="请输入" v-model:value="data.sms_account" />
            </a-form-item>
            <a-form-item label="短信密码">
              <a-input placeholder="请输入" v-model:value="data.sms_password" />
            </a-form-item>
            <div class="bottom">
              <a-button type="primary" @click="onSubmit">保存</a-button>
              <!-- <a-button style="margin-left: 10px" @click="resetForm"
                >取消</a-button
              > -->
            </div>
          </a-form>
        </div>
      </a-tab-pane>
      <a-tab-pane key="6" tab="汇款通知信息">
        <div class="tab6">
          <a-form>
            <a-form-item label="短信 ID">
              <a-input
                placeholder="请输入"
                v-model:value="data.notify_sms_userid"
              />
            </a-form-item>
            <a-form-item label="短信账号">
              <a-input
                placeholder="请输入"
                v-model:value="data.notify_sms_account"
              />
            </a-form-item>
            <a-form-item label="短信密码">
              <a-input
                placeholder="请输入"
                v-model:value="data.notify_sms_password"
              />
            </a-form-item>
            <a-form-item label="推送手机号">
              <a-textarea
                placeholder="多个手机号码，以 , 隔开"
                v-model:value="data.notify_phones"
              />
            </a-form-item>
            <a-form-item label="模板ID">
              <a-input
                placeholder="请输入"
                v-model:value="data.notify_wx_template_id"
              />
            </a-form-item>
            <div class="bottom">
              <a-button type="primary" @click="onSubmit">保存</a-button>
              <!-- <a-button style="margin-left: 10px" @click="resetForm"
                >取消</a-button
              > -->
            </div>
          </a-form>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import Upload from "../../components/Upload.vue";
import { getSystemIndex, getSystemSave } from "../../service/disposesetup";
import { message } from "ant-design-vue";
// import { UploadOutlined } from "@ant-design/icons-vue";
// import request from 'umi-request';

export default {
  name: "DisposeSetup",
  setup() {
    const state = reactive({
      data: {},
      // fileList: "",
    });
    onMounted(() => {
      init();
    });
    const init = async () => {
      const res = await getSystemIndex({
        access_token: sessionStorage.getItem("token"),
      });
      console.log(res);
      state.data = res.data.data;
      // console.log(state.data.system_full_name);
    };

    // const resetForm = () => {};
    const onSubmit = async () => {
      // console.log(state.fileList)
      const res = await getSystemSave({
        access_token: sessionStorage.getItem("token"),
        ...state.data,
      });
      // console.log(res);
      if (res.data.status === 100000) {
        message.success(res.data.msg);
        init();
      } else {
        message.error(res.data.msg);
      }
    };
    // const fileList = ref([]);
    // const handleChange = info => {
    //   if (info.file.status !== 'uploading') {
    //     console.log(info.file, info.fileList);
    //   }

    //   if (info.file.status === 'done') {
    //      console.log(info.file.response);
    //     message.success(`${info.file.name} file uploaded successfully`);
    //   } else if (info.file.status === 'error') {
    //     message.error(`${info.file.name} file upload failed.`);
    //   }
    // };
    const uploadfile=(e)=>{
      console.log(e)
    }
    return {
      activeKey: ref("1"),
      // resetForm,
      onSubmit,
      ...toRefs(state),uploadfile
      // fileList,
      // headers: {
      //   authorization: 'authorization-text',
      // },
      // handleChange,
    };
  },
  components: { Upload, },
};
</script>
<style>
.DisposeSetup .ant-form-item-label {
  min-width: 120px;
}
</style>
<style  scoped>
.tab1,
.tab3,
.tab2,
.tab4,
.tab6 {
  padding: 15px;
}
.tab1 input,
.tab2 input,
.tab3 input,
.tab3 .ant-input,
.tab4 input,
.tab6 input,
.tab6 .ant-input {
  width: 400px;
}
.tab3 textarea {
  min-height: 200px;
}
.bottom {
  margin-left: 120px;
}
.fromImg {
  width: 110px;
  height: 110px;
}
/* .avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
} */
</style>